@import "../../../public/style.scss";


.EPanel {
    display: flex;
    flex-direction: column;
    padding: 1rem 0rem;
    margin-right: 0rem;
    flex-grow: 1;
    background-color: rgba( $gn-accent-color-2, 1 );
    height: calc( 100vh - 30px);
    overflow: hidden;
    transition: all 0.25s ease-in-out;
    .E-Row-Holder {
      display: flex;
      flex-direction: row;
      padding : 0rem 0rem;
      .E-Icon, .E-Remove {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color : rgba( $gn-frame-color, 1 );
        transition: all 0.25s ease-in;
        font-size: 100%;
        height: auto;
        padding : 0.25rem 1rem;
        flex-shrink: 0;
        flex-basis: fit-content;
        &:hover {
          background-color: rgba( $gn-frame-color, 1 );
          color : rgba( $gn-white-color, 1 );
        }
      }
      .E-Name {
        color : rgba( $gn-frame-color, 1 );
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-bottom-color: transparent;
        padding: 0rem 0.5rem;
        cursor: default;
        &:hover {
          border-bottom-color : rgba( $gn-frame-color, 1 );
        }
      }
      .E-Remove {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color : rgba( $gn-red-color, 1 );
        transition: all 0.25s ease-in;
        font-size: 100%;
        padding : 0.25rem 1rem;
        flex-shrink: 0;
        &:hover {
          background-color: rgba( $gn-red-color, 1 );
          color : rgba( $gn-white-color, 1 );
        }
      }
      &.Expand {
        .btn-outline {
          width : 50%;
          //height: 25px;
          padding : 0.25rem 0rem;
          &:first-child {
            border-right-style: none;
          }
          &.Selected {
            background-color: rgba( $gn-frame-color, 1 );
            color : rgba( $gn-accent-color, 1 );
            &:hover {
              color : rgba( $gn-white-color, 1 );
            }
          }
          &:hover {
            background-color: rgba( $gn-accent-color, 1 );
            border-color: rgba( $gn-accent-color, 1 );
          }
        }
      }
      .E-Input-Holder {
        display: flex;
        flex-direction: column;
        padding: 0.25rem;
        width: 50%;
        .E-Input-Title {
          font-size: 80%;
          font-weight: bold;
          color : rgba( $gn-frame-color, 1 );
        }
        input {
          color : rgba( $gn-frame-color, 1 );
        }
      }
      .btn-outline {
        &:first-child {
          border-top-left-radius: 0.5rem;
          border-bottom-left-radius: 0.5rem;
        }
        &:last-child {
          border-top-right-radius: 0.5rem;
          border-bottom-right-radius: 0.5rem;
        }
      }
    }
    .E-Grow-Holder {
      display: flex;
      flex-direction: column;
      padding : 0rem 1rem;
      margin-top: 1rem;
      margin-bottom: 1rem;
      flex-grow: 1;
      //overflow: auto;
      overflow: hidden;
      .E-Grow-Content {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        overflow: hidden;
        padding-top: 2rem;
        .E-Title {
          text-align: center;
          padding:0.5rem 0rem;
          background-color :rgba( $gn-frame-color, 1 );
          font-size: 100%;
          font-weight: bolder;
          border: 1px solid rgba( $gn-frame-color, 0.1 );
          border-top-left-radius: 0.5rem;
          border-top-right-radius: 0.5rem;
          color : rgba( $gn-accent-color-2, 1 );
          border-bottom-width : 0px;
        }
        .Symbols {
          margin: 0rem 0rem;
          margin-bottom: 0rem;
          padding : 0rem 0rem;
          height: auto;
          //overflow: auto;
          border : 1px solid rgba($gn-frame-color, 0.1);
          //border : 2px solid rgba($gn-frame-color, 1);
          .form-control {
            //width: calc( 100% - 1rem );
            //height: fit-content;
            padding:0.5rem 0.5rem;
            border : 0px solid rgba($gn-frame-color, 0.1);
            border-radius: 0rem;
            background-color:  rgba( $gn-accent-color-3, 1 );
            color : rgba($gn-frame-color, 1);
            max-height: calc( 100vh - 30px - 300px - 75px - 80px - 2rem - 35px - 1rem - 35px - 1rem - 0px );
            overflow: auto;
            //border-top-left-radius: 0.5rem;
            //border-top-right-radius: 0.5rem;
            .rbt-token {
              background-color: rgba( $gn-frame-color, 1 );
              color : rgba( $gn-accent-color-2, 1 );
              font-weight: bolder;
              transition: all 0.25s ease-in;
              &:hover {
                background-color: rgba( $gn-badge-color, 1 );
                //color : rgba( $gn-white-color, 1 );
              }
            }
          }
          input {
            margin: 0.5rem 0rem;
            color : rgba($gn-frame-color, 1);
            margin: 0px;
            height: 25px;
          }
          .dropdown-menu {
            max-height: 200px !important;
          }
        }
        .E-Save {
          display: flex;
          flex-direction: row;
          .btn {
            padding : 0.25rem 0.5rem;
            font-size: 80%;
            border-top-left-radius: 0rem;
            border-top-right-radius: 0rem;
            &.Save {
              margin-left: auto;
            }
          }
        }
      }
    }
    .E-Holder {
      display: flex;
      flex-direction: column;
      //padding : 1rem;
      flex-shrink: 0;
      .Tabs {
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid rgba( $gn-frame-color, 0.1 );
        padding : 0rem 1rem;
        .Tab {
          width: 50%;
          padding : 0rem;
          height: 30px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          border-bottom-width: 0px;
          &.btn-outline  {
            border-color: rgba($gn-frame-color, 0.1);
          }
          &.Disabled {
            border-top-color: transparent;
            border-left-color: transparent;
            border-right-color: transparent;
            color : rgba( $gn-frame-color, 0.5 );
            &:hover {
              color : rgba( $gn-text-color, 1 );
            }
          }
        }
      }
      &.Expand {
        flex-grow: 1;
      }
      .btn {
        margin: 1rem;
      }
    }
    .E-Content {
      padding : 1rem;
      flex-grow: 1;
      overflow: auto;
      display: flex;
      flex-direction: column;
      height: calc(100vh - 30px - 45.5px - 2rem - 1rem - 30px - 100px);
      padding-bottom: 100px;
      background-color: rgba( $gn-accent-color-2, 1 );
      transition: all 0.25s ease-in;
      .E-Empty {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .E-List {
        flex-shrink: 0;
        background-color: rgba( $gn-accent-color-2, 1 );
        border-bottom: 1px solid rgba( $gn-frame-color, 0.1 );
        padding : 1rem 0.5rem;
        color : rgba( $gn-frame-color, 1 );
        transition: all 0.25s ease-in;
        overflow: auto;
        cursor: pointer;
        //height: 25px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        text-overflow: ellipsis;
        text-wrap: nowrap;
        overflow: hidden;
        white-space: nowrap;
        &:hover {
          background-color: rgba( $gn-frame-color, 1 );
          color : rgba( $gn-text-color-contrast, 1 );
        }
        &.T {
          color : rgba( $gn-frame-color, 1 );
          border-bottom: 1px solid rgba( $gn-frame-color, 1 );
          &:hover {
            background-color: rgba( $gn-accent-color, 1 );
            color : rgba( $gn-text-color, 1 );
          }
        }
        span {
          margin-left: 0.5rem;
        }
      }
    }
}