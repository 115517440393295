@import "../../public/style.scss";

.Header {
  background-color: rgba( $gn-accent-color-2 , 1 );
  display: flex;
  flex-direction: row;
  position: absolute;
  z-index: 10;
  top:0rem;
  left:0rem;
  width: 100%;
  height: 30px;
  transition: all 0.25s ease-in-out;
  .Left {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0rem 1rem;
    height: 100%;
    transition: all 0.25s ease-in;
    .Fa {
      font-size: 100%;
      color : rgba($gn-frame-color, 1.0);
      cursor: pointer;
      transition: all 0.25s ease-in;
    }
    &:hover {
      background-color: rgba($gn-frame-color, 1.0);
      .Fa {
        color : rgba($gn-white-color, 1.0);
      }
    }
  }
  .Theme-Mode {
    margin-left: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    span {
      color : rgba($gn-text-color, 1);
      font-size: 60%;
    }
  }
  .Languages {
    display: flex;
    flex-direction: row;
    //margin-left: auto;
    justify-content: center;
    align-items: center;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    .Options {
      color : rgba($gn-text-color, 1.0);
      font-weight: bold;
      padding : 0rem 0.5rem;
      margin: 0.25rem 0rem;
      cursor: pointer;
      transition: all 0.25s ease-in;
      &:hover {
        color : rgba($gn-frame-color, 1.0);
        background-color: rgba($gn-text-color-contrast, 1.0);
        
      }
      &:not(:last-child) {
        border-right: 1px solid rgba($gn-text-color, 0.1);
      }
    }
  }
  &.Contrast {
    border-bottom: 1px solid rgba($gn-frame-color, 0.1);
    
    .Languages {
      .Options {
        color : rgba($gn-frame-color, 1.0);
        &:hover {
          color : rgba($gn-text-color-contrast, 1.0);
          background-color: rgba($gn-frame-color, 1.0);
        }
        &:not(:last-child) {
          border-right: 1px solid rgba($gn-frame-color, 0.1);
        }
      }
    }
      
  }
}


