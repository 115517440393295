@import "../../public/style.scss";

.Logon {
  background-color: rgba( $gn-accent-color-2 , 1 );
  display: flex;
  flex-direction: 1;
  width : 100%;
  height : 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.25s ease-in-out;
  .L-Frame {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width:320px;
    .L-Logo {
      //width : 80px;
      height: calc(100px + 2rem);
      padding: 1rem 0rem;
    }
    .L-Name {
      font-size: larger;
      margin-bottom: 2rem;
      color : rgba( $gn-white-color , 1 );
      font-weight: bolder;
    }
    .L-Holder {
      display: flex;
      flex-direction: column;
      padding : 0.5rem 0.5rem;
      min-width: 300px;
      .L-Title {
        color : rgba( $gn-text-color , 0.5 );
        font-size: small;
        padding-bottom: 0.25rem;
        font-weight: bolder;
      }
      .L-Input {
        color : rgba( $gn-text-color , 1 );
        height:  40px;
      }
    }
    .L-Btn {
      margin-top: 1rem;
    }
  }
}


