@import "../../../../public/style.scss";

.I-Cover {
  flex-grow : 1;
  position: relative;
  flex-direction: column;
  display: flex;
  justify-content: center;
  border:0px solid rgba( $gn-frame-color, 1 ) ;
  //border-radius: 0.5rem;
  //border-top:1px solid rgba( $gn-frame-color, 0.1 ) ;
  border-top-left-radius : 0px;
  border-bottom-left-radius : 0px;
  background-color: rgba( $gn-accent-color-2, 1 );
  color : rgba( $gn-text-color ,1 );
  align-items: center;
  transition: all 0.25s ease-in-out;
  .IDE-Logo {
    height:  100px;
    //width: fit-content;
    object-fit: cover;
  }
  .S-Holder {
    display: flex;
    flex-direction: column;
    padding : 1rem;
    flex-shrink: 0;
    .btn{
      width:300px;
    }
    &.Expand {
      flex-grow: 1;
    } 
    span {
      opacity: 0.8;
      font-size: 80%;
    }
  }
  .I-Container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    .Holder {
      width : 250px;
      margin: 0.5rem;
      border: 1px solid rgba( $gn-accent-color, 0.1 );
      border-radius: 0.5rem;
      height : 140px;
      padding : 0.5rem 1rem;
      background-color: rgba( $gn-accent-color, 0.25 );
      cursor: pointer;
      transition: all 0.25s ease-in;
      .Name {
        color : rgba( $gn-frame-color, 1 );
        font-weight: bolder;
        margin-bottom: 0.5rem;
        transition: all 0.25s ease-in;
      }
      .Badge-Holder {
        display: flex;
        flex-direction: row;
        padding : 0.25rem 0rem;
        flex-wrap: wrap;
        margin-bottom: 0.5rem;
        .Badge {
          font-size: 75%;
          background-color: rgba( $gn-accent-color, 1 );
          color : rgba( $gn-frame-color, 1 );
          padding : 0rem 0.5rem;
          border-radius: 0.5rem;
          margin-right: 0.25rem;
          &:last-child {
            margin-right: 0rem;
          }
        }
      }
      .Prompt {
        color : rgba( $gn-text-color, 1 );
        font-size: 75%;
        transition: all 0.25s ease-in;
      }
      &:hover {
        background-color: rgba( $gn-frame-color, 0.25 );
        border: 1px solid rgba( $gn-frame-color, 0.1 );
      }
    }
  }
  
}


@media screen and (max-width: 751px)  {
  .I-Cover {

  }
}