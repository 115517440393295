@import "../../../public/style.scss";

.Simple {
  flex-grow : 1;
  position: relative;
  flex-direction: column;
  display: flex;
  justify-content: center;
  border:0px solid rgba( $gn-frame-color, 1 ) ;
  //border-radius: 0.5rem;
  border-top:1px solid rgba( $gn-frame-color, 0.1 ) ;
  border-top-left-radius : 0px;
  border-bottom-left-radius : 0px;
  &.I-Cover {
    background-color: rgba( $gn-accent-color-2, 1 );
    color : rgba( $gn-white-color ,1 );
    align-items: center;
    .IDE-Logo {
      height:  100px;
      //width: fit-content;
      object-fit: cover;
    }
  }
  
  .Wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    &.Center {
      justify-content: center;
      align-items: center;
      background-color: rgba( $gn-accent-color-2, 1 );
      color : rgba( $gn-white-color ,1 );
      cursor: pointer;
      
    }
    .Holder {
      width: 100%;
      max-width: 700px;
      padding : 0rem 1rem;
      
      .Title {
        padding:0.5rem 0rem;
        background-color: rgba( $gn-frame-color ,1 );
        //color : rgba( $gn-dark-color, 1 );
        color : rgba( $gn-text-color-contrast, 1 );
        border: 1px solid rgba( $gn-frame-color ,0.1 );
        font-weight: bold;
        text-align: center;
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
      }
      .Desc {
        border: 1px solid rgba( $gn-frame-color ,0.1 );
        border-bottom-left-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
        background-color: rgba( $gn-accent-color-2, 1 );
        color : rgba( $gn-frame-color ,1 );
        padding:0.5rem 1rem;
        font-weight: bolder;
      }
    }
  }

  .Bottom {
    .Prompt {
      transition: all 0.25s ease-in-out;
      background-color:  rgba( $gn-bgcolor, 1 );
      border-width: 0px;
      border-top: 1px solid rgba( $gn-frame-color, 0.1 );
      padding: 1rem 0.5rem;
      height: 150px;
      color : rgba( $gn-frame-color, 1 );
      font-size: 100%;
      font-weight: bold;
      font-style: italic;
      flex-grow: 1;
      padding-right: calc(20px + 2rem);
    }
    .Btn {
      transition: all 0.25s ease-in-out;
      padding: 1rem;
      background-color: rgba( $gn-frame-color, 1 );
      justify-content: center;
      align-items: center;
      transition: all 0.25s ease-in;
      bottom: 0px;
      right: 0px;
      position: absolute;
      border-top-left-radius: 1rem;
      .Fa {
        transition: all 0.25s ease-in;
        color : rgba( $gn-white-color ,1 );
        font-size: 20px;
      }
      &:hover {
        background-color: rgba( $gn-accent-color, 1 );
        .Fa {
          color : rgba( $gn-frame-color ,1 );
        }
      }
    }
  }

  .S-Holder {
    display: flex;
    flex-direction: column;
    padding : 1rem;
    flex-shrink: 0;
    .btn{
      width:300px;
    }
    &.Expand {
      flex-grow: 1;
    }
  }
}


@media screen and (max-width: 751px)  {
  .Simple {

  }
}