@import "../../public/style.scss";

.Loading {
  background-color: rgba( $gn-accent-color-2 , 1 );
  display: flex;
  flex-direction: 1;
  width : 100%;
  height : 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .L-Logo {
    width : 100px;
    height: 100px;
  }
  .Message {
    padding-top: 1.5rem;
    font-size: larger;
    color : rgba( $gn-text-color , 1 );
  }
  &.C {
    position: fixed;
    top:0px;
    left: 0px;
    z-index : 99;
    //background-color: rgba( $gn-frame-color , 0.75 );
    backdrop-filter: blur(2px);
  }
}


