@import "../../public/style.scss";

.Editor {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  height: calc( 100vh - 30px);
  background-color: rgba( $gn-frame-color-4, 1 );
  //background-color: rgba( $gn-frame-color, 0.1 );
  margin-top: 30px;
  transition: all 0.25s ease-in-out;
  .Panel-Wrapper {
    position: relative;
    z-index : 2;
    width:300px;
    display: flex;
    flex-direction: column;
    height: 100%;
    transition: all 0.25s ease-in-out;
    flex-shrink: 0;
    border-right: 1px solid rgba( $gn-frame-color, 0.1 );
    &.Hide {
      width : 0px;
      overflow: hidden;
      border-right: 0px solid rgba( $gn-black-color, 0.1 );
    }
  }
  .IDE-Wrapper {
    position: relative;
    z-index : 1;
    flex-grow : 1;
    height: 100%;    
    display: flex;
    flex-direction: column;
    width:100%;
    min-width: 300px;
    margin-left: -1px;
    transition: all 0.25s ease-in-out;
    //padding-left: 1px;
    //border-left: 1px solid rgba( $gn-dark-color, 0.05 );
    .IDE-Control {
      display: flex;
      flex-direction: row;
      //height:50px;
      height: fit-content;
      margin-bottom: 0rem;
      margin-top: 0rem;
      position: relative;
      z-index :2;
      transition: all 0.25s ease-in-out;
      .IDE-Chevron {
        border-top-style: none;
        border-bottom-style: none;
        border-left-style: none;
        border-right-color: rgba( $gn-frame-color, 0.1 );
        padding : 0.5rem 1rem;
        background-color: rgba( $gn-accent-color-2, 1 );
        cursor: pointer;
        display: flex;
        flex-direction: row;
        &.Unlimited {
          width :auto;
        }
        &:hover {
          //background-color: rgba( $gn-frame-color, 1 );
          .Fa, .Name {
            color : rgba( $gn-accent-color, 1 );
          }
        }
        .Fa {
          transition: all 0.25s ease-in-out;
          color : rgba( $gn-frame-color, 1 );
          margin:auto;
          cursor: pointer;
        }
        .Name {
          transition: all 0.25s ease-in-out;
          color : rgba( $gn-frame-color, 1 );
          margin:auto;
          margin-right: 0.5rem;
          cursor: pointer;
        }
      }
      .Modes {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: 100%;
        .btn-outline {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding : 0rem 1rem;
          border-style: none;
          border-left-style: solid;
          height: 100%;
          border-color: rgba($gn-frame-color, 0.1);
          &:first-child {
            border-left-style: none;
          }
          &:last-child {
            border-right-style: solid;
          }
          &.Selected {
            background-color: rgba( $gn-frame-color, 1 );
            color : rgba( $gn-accent-color, 1 );
          }
        }
      }
      .Modes2 {
        //display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        display: none;
        background-color: rgba( $gn-frame-color, 1 );
        color : rgba( $gn-black-color, 1 );
        font-weight: bolder;
        padding: 0.5rem 0rem;
      }
      .Update {
        border-top-style: none;
        border-bottom-style: none;
        border-right-style: none;
        padding : 0.5rem 1rem;
        margin-left: auto;
        border-left-color: rgba( $gn-frame-color, 0.1 );
      }
    }
    .IDE-Content {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      position: relative;
      z-index :1;
      width : 100%;
      transition: all 0.25s ease-in;
    }
  }
}

@media screen and (max-width : 500px) {
  .Editor {
    .IDE-Wrapper {
      .IDE-Control {
        .Modes {
          display: none;
        }
        .Modes2 {
          display: flex;
        }
      }
    }
  }
}