@import "./public/style.scss";

.App {
  background-color: rgba( $gn-frame-color , 1 );
  display: flex;
  flex-direction: 1;
  width : 100%;
  height : 100vh;
  overflow: auto;
  transition: all 0.25s ease-in-out;
  &.Contrast {
    background-color: rgba( $gn-white-color , 1 );
    transition: all 0.25s ease-in-out;
  }
}


