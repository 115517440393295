@import "../../../public/style.scss";

.IDE {
  flex-grow : 1;
  position: relative;
  //min-height: 30px;
  flex-direction: column;
  display: flex;
  justify-content: center;
  //background-color: rgba( $gn-dark-color, 0 );
  //backdrop-filter: blur(2px);
  //-webkit-backdrop-filter: blur(2px);
  border:0px solid rgba( $gn-frame-color, 1 ) ;
  //border-radius: 0.5rem;
  border-top:1px solid rgba( $gn-frame-color, 0.1 ) ;
  border-top-left-radius : 0px;
  border-bottom-left-radius : 0px;
  //margin:0.5rem;
  //margin-top: 0.5rem;
  .Textarea {
    height: 100% !important;
    width:100% !important;
    border-style: none;
    background-color: rgba( $gn-accent-color-2, 1 );
    position: absolute;
    top:0px;
    left : 0px;
    right: 0px;
    bottom:0px;
    .ace_gutter {
      width: 40px !important;
      background-color: rgba( $gn-frame-color , 1 );
      .ace_gutter-layer {
        width: 40px !important;
        .ace_gutter-cell  {
          padding:0px 5px !important;
          color : rgba( $gn-accent-color-2 ,1 ) !important;
          &.ace_gutter-active-line { 
            color : rgba( $gn-frame-color ,1 ) !important;
            background-color: rgba( $gn-accent-color-2  ,1 ) !important;
          }
        }
      }
    }

    .ace_scroller {
      //left: 27.4023px !important;
      .ace_content {
        width :calc( 100% - 8px ) !important;
        //transform: translateX(0px) !important;;
        //transform : translate(0px, -20px) !important;
        border-style: none;
        .ace_text-layer {
          width :calc( 100% - 8px ) !important;
          //margin : 0px;
        }
        //width:100% !important;
      }
    }
  }
  &.I-Cover {
    background-color: rgba( $gn-dark-color, 1 );
    color : rgba( $gn-text-color ,1 );
    align-items: center;
  }
}


@media screen and (max-width: 751px)  {
  .Garlican-IDE {

  }
}