@import "./public/style.scss";
//@import "/style.scss";

body, html {
  margin: 0;
  font-family: $gn-font !important;
  /*font-size: 12px !important;*/
  font-weight: 400;
  font-size:14px !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    width : auto !important;
    height : auto !important;
    min-height: auto !important;
    min-width: auto !important;
}

.up {
  background-color : rgba($gn-green-color, 0.8)!important;
  color : rgba($gn-white-color, 1) !important;
}

.down {
  background-color : rgba($gn-red-color, 0.8)!important;
  color : rgba($gn-white-color, 1) !important;
}

